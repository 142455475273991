var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"balance-view__entire-body"},[_c('v-data-table',{attrs:{"headers":_vm.header,"items":_vm.sponsorLinkItem},scopedSlots:_vm._u([{key:"item.#",fn:function(ref){
var item = ref.item;
return [(item && item.user && item.user[0])?_c('label',[_vm._v(" "+_vm._s(item.user[0].firstName)+" "+_vm._s(item.user[0].lastName)+" ")]):(
          item && item.organization && item.organization[0] && item.orgUser && item.orgUser[0]
        )?_c('label',[_vm._v(" "+_vm._s(item.organization[0].name)+" - "+_vm._s(item.orgUser[0].firstName)+" "+_vm._s(item.orgUser[0].lastName)+" ")]):_vm._e()]}},{key:"item.shareCode",fn:function(ref){
        var item = ref.item;
return [_c('v-row',{staticClass:"pl-3 align-center"},[_vm._v(" "+_vm._s(("https://www.pilotcity.com/sponsor/" + (item.shareCode)))+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.copy(("https://www.pilotcity.com/sponsor/" + (item.shareCode)))}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Copy link")])])],1)]}},{key:"item.uses",fn:function(ref){
        var item = ref.item;
return [(item.creationType == 'manual')?_c('label',[_vm._v(_vm._s(_vm.formatUses(item, false)))]):_c('label',[_vm._v("-")])]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('v-switch',{staticClass:"align-center mb-2",attrs:{"hide-details":"","inset":"","color":"green"},on:{"click":function($event){return _vm.toggleSwitch(item.linkStatus, item.shareCode)}},model:{value:(item.linkStatus),callback:function ($$v) {_vm.$set(item, "linkStatus", $$v)},expression:"item.linkStatus"}})]}},{key:"item.revoke",fn:function(ref){
        var item = ref.item;
return [(item.creationType == 'manual')?_c('v-btn',{attrs:{"disabled":(item.userId && item.type === 'organization' && item.userId !== _vm.user._id) ||
          _vm.formatUses(item, true),"color":"red","outlined":"","depressed":"","small":"","loading":_vm.isDeleting && item.shareCode === _vm.currentCode},on:{"click":function($event){return _vm.$emit('revoke', item)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-delete")]),_vm._v(" Delete ")],1):_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","loading":_vm.isGenerating,"large":""},on:{"click":function($event){return _vm.regenerateLink(item.shareCode)}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,true)},[_c('span',[_vm._v("Regenerate Sponsor Link")])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }