





















































































import { ref, computed } from '@vue/composition-api';
import store, { useUserState, useProgramActions } from '@/store';
import { ActionTypes } from '@/store/modules/tools/actions';
import { SPONSOR_LINKS_TABLE_HEADER } from '@/constants/portfolio';

export default {
  name: 'SponsorLinksTable',

  props: {
    items: { required: true, type: Array },
    sponsors: { required: true, type: Array },
    fetchLinks: { type: Function, default: () => {} },
    isDeleting: { type: Boolean, default: false },
    currentCode: {
      type: String,
      default: ''
    }
  },

  setup(props, ctx) {
    const { regenerateSponsorLink, toggleSponsorLinkStatus } = useProgramActions([
      'regenerateSponsorLink',
      'toggleSponsorLinkStatus'
    ]);
    const sponsorLinkItem: any = computed({
      get: () =>
        props?.items?.map(item => ({
          ...item,
          linkStatus: item?.status === 'active' ? !!item.status : !item.status
        })),
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const toggleSwitch = async (linkStatus, share_code) => {
      const status = linkStatus ? 'active' : 'inactive';
      const res: any = await toggleSponsorLinkStatus({ share_code, status });
      if (res) {
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          message: res?.message,
          type: 'success',
          isShowSnackbar: true
        });
        await props.fetchLinks();
      } else {
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          message: 'Something went wrong',
          type: 'error',
          isShowSnackbar: true
        });
      }
    };
    const { user }: any = useUserState(['user']);
    const isGenerating = ref(false);
    const formatUses = (item, checkDeletion) => {
      const keysValue = Object.keys(item?.claimedBy);
      let claims = 0;
      if (keysValue?.length >= 1) {
        for (let b = 0; b < keysValue?.length; b++) {
          claims += item?.claimedBy[keysValue[b]]?.count;
        }
      }
      const total = item.balance || Object.keys(item?.tokenPool)?.length;
      if (checkDeletion) {
        if (claims !== 0) {
          return true;
        }
        return false;
      }
      return `${claims} / ${total}`;
    };

    const copy = str => {
      navigator.clipboard.writeText(str);
    };

    async function regenerateLink(shareCode) {
      try {
        const payload = {
          share_code: shareCode,
          link_type: 'organization'
        };
        isGenerating.value = true;
        const res: any = await regenerateSponsorLink(payload);
        if (res) {
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: 'SponsorLink Regenerated Successfully',
            type: 'success',
            isShowSnackbar: true
          });
          await props.fetchLinks();
          isGenerating.value = false;
        }
      } catch (error) {
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          message: 'Something went wrong',
          type: 'error',
          isShowSnackbar: true
        });
      }
    }

    return {
      header: ref(SPONSOR_LINKS_TABLE_HEADER),
      user,
      formatUses,
      copy,
      isGenerating,
      regenerateLink,
      sponsorLinkItem,
      toggleSwitch
    };
  }
};
